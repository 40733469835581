<template>
  <table class="table table-striped table-hover logtable">
    <thead>
      <tr>
        <th class="index">日時/分類/担当</th>
        <th class="content">内容</th>
        <th class="delete">削除</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="log in logList"
        :key="log.id"
      >
        <td class="index">
          <div class="date">{{log.date}}</div>
          <div class="genre">{{log.genre}}</div>
          <div class="tantou">{{log.tantou}}</div>
        </td>
        <td class="content">{{log.content}}</td>
        <td class="delete">
          <button
            class="btn btn-secondary btn-sm"
            :value="log.id"
            @click="deleteLog"
          >×</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ["id", "mode", "animalData"],
  data() {
    return {
      logList: [],
      capability: ""
    }
  },
  mounted() {
    this.$store.dispatch('AnimalLog/get', {
      id: this.$route.params.id,
    })
    this.$store.watch(
      (state, getters) => getters['AnimalLog/logList'],
      (newValue) => { this.logList = newValue }
    )
    this.$store.watch(
      (state, getters) => getters['AnimalLog/update'],
      (newValue) => {
        if(newValue === false) {
          this.$store.dispatch('AnimalLog/get', {
            id: this.$route.params.id,
          })
        }
      }
    )
    this.capability = this.$store.getters["Authorize/capability"]
  },
  methods: {
    deleteLog(e) {
      if( confirm("削除してよろしいですか？") ){
        this.$store.commit('AnimalLog/update', true)
        this.$store.dispatch('AnimalLog/delete', {
          id: e.target.value
        })
      }
    }
  },
  watch: {
    mode: function(newVal) {
      console.log(newVal)
    },
    animalData: function() {
      this.$store.dispatch('AnimalLog/get', {
        id: this.$route.params.id,
      })
    }
  }
}
</script>
