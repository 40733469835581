<template>
  <b-modal
    id="vaccination-modal"
    title="ワクチン接種記録"
    v-model="active"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="cancel"
  >

    <div class="form-floating mb-3">
      <input
        type="date"
        class="form-control"
        :class="{'is-invalid': !states.vaccination_date}"
        id="executiondate"
        placeholder="接種日"
        v-model="formData.vaccination_date"
      >
      <label for="executiondate">接種日</label>
      <div class="invalid-feedback">接種日を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <select
        id="vaccintype"
        v-model="formData.injection_solution"
        class="form-select"
        :class="{'is-invalid': !states.injection_solution}"
      >
        <option selected>狂犬病予防ワクチン</option>
      </select>
      <label for="vaccintype">ワクチン種別</label>
      <div class="invalid-feedback">ワクチン種別を選択してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="text"
        class="form-control"
        :class="{'is-invalid': !states.lot_number}"
        id="lotnumber"
        placeholder="ロット番号"
        v-model="formData.lot_number"
      >
      <label for="lotnumber">ロット番号</label>
      <div class="invalid-feedback">ロット番号を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="date"
        class="form-control"
        :class="{'is-invalid': !states.expiration_date}"
        id="expiration"
        placeholder="有効期限"
        v-model="formData.expiration_date"
      >
      <label for="expiration">有効期限</label>
      <div class="invalid-feedback">有効期限を入力してください</div>
    </div>

    <div class="mb-3">
      <label for="vaccinationMemo" class="form-label">備考</label>
      <textarea
        class="form-control"
        id="vaccinationMemo"
        rows="3"
        v-model="formData.memo"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >追加する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["animalData","modal"],
  emits: ["reset", "retriveVaccinLog"],
  data() {
    return {
      active: false,
      formData: {
        vaccination_date: "",
        injection_solution: "",
        lot_number: "",
        expiration_date: "",
        memo: "",
      },
      states: {
        vaccination_date: true,
        injection_solution: true,
        lot_number: true,
        expiration_date: true,
      },
    }
  },
  methods: {
    validate() {
      this.states.vaccination_date = this.formData.vaccination_date ? true : false
      this.states.injection_solution = this.formData.injection_solution ? true : false
      this.states.lot_number = this.formData.lot_number ? true : false
      this.states.expiration_date = this.formData.expiration_date ? true : false
      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      if(!this.validate())
        return
      
      axios
        .post("/wp-json/pasti/v1/vaccination", {
          animal_id: this.$route.params.id,
          vaccination_date: this.formData.vaccination_date,
          injection_solution: this.formData.injection_solution,
          lot_number: this.formData.lot_number,
          expiration_date: this.formData.expiration_date,
          memo: this.formData.memo,
          microchip_no: this.$props.animalData.microchip_no,
          my_number: this.$props.animalData.my_number,
        })
        .then( response => {
          console.log(response)
          this.active = false
          this.resetForm()
          this.$emit('retriveVaccinLog')
        })
        .catch((error) => {
          console.log(error)
        });
    },
    cancel() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      let formdata = this.formData
      Object.keys(formdata).forEach(function(key) {
        formdata[key] = ""
      })
      this.formData = formdata
      let states = this.states
      Object.keys(states).forEach(function(key) {
        states[key] = true
      })
      this.states = states
    }
  },
  watch: {
    modal: function(newVal) {
      this.active = newVal
    }
  }
}
</script>