<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>日付／担当医</th>
        <th>接種内容</th>
        <th>有効期限</th>
        <th class="delete">削除</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in vaccinLog"
        :key="row.rabies_id"
      >
        <td class="index">
          <div class="date">{{row.vaccination_date}}</div>
          <div class="doctor">{{row.carryout_vaccine_veterinarian_name}}</div>
        </td>
        <td class="content">
          {{row.injection_solution}}（lotno.{{row.lot_number}}）
          <div
            v-if="row.memo"
          >{{row.memo}}</div>
        </td>
        <td>
          {{row.expiration_date}}
        </td>
        <td class="delete">
          <button
            class="btn btn-secondary btn-sm"
            :value="row.rabies_id"
            @click="deleteLog"
          >×</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['vaccinLog'],
  methods: {
    deleteLog(e) {
      if( confirm("削除してよろしいですか？") ){
        this.$store.commit('AnimalLog/update', true)
        this.$store.dispatch('AnimalLog/deleteVaccinationLog', {
          id: e.target.value
        })
      }
    }
  }
}
</script>

<style scoped>
td:not(.content) {
  width: 15%;
}
td.content {}
td.content div {
  font-weight: normal;
  font-size: 0.9rem;
}
</style>