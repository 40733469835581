<template>
  <nav id="breeder-control">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <span
          :class="[{active: selected==='breeding'}, 'nav-link']"
          @click="setPane('breeding')"
        >飼養記録</span>
      </li>
      <li class="nav-item">
        <span
          class="nav-link"
          :class="[{active: selected==='vaccination'}, 'nav-link']"
          @click="setPane('vaccination')"
        >予防接種</span>
      </li>
      <li class="nav-item">
        <span
          class="nav-link"
          :class="[{active: selected==='healthcheck'}, 'nav-link']"
          @click="setPane('healthcheck')"
        >健康診断</span>
      </li>
      <li class="nav-item">
        <span
          class="nav-link"
          :class="[{active: selected==='yobou'}, 'nav-link']"
          @click="setPane('yobou')"
        >予防処置</span>
      </li>
      <li class="nav-item">
        <span
          class="nav-link"
          :class="[{active: selected==='caesarean'}, 'nav-link']"
          @click="setPane('caesarean')"
        >帝王切開</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  emits: ["selected"],
  data() {
    return {
      selected: 'breeding'
    }
  },
  methods: {
    setPane(target) {
      this.selected = target
      this.$emit("selected", target)
    }
  }
}
</script>

<style scoped>
nav {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
</style>