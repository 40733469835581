<template>

    <button
      @click="openModal"
      class="btn btn-primary mb-3"
    >＋健康診断記録</button>

    <HealthCheckModal
      :modal="modal"
      :animalData="animalData"
      @reset="closeModal"
      @retriveHealthCheck="loadHealthCheckLog"
    />

    <HealthCheckLogTable
      :healthCheckLog="healthCheckLog"
    />

</template>

<script>
import axios from "axios"
import HealthCheckModal from "@/components/Modal/HealthCheck"
import HealthCheckLogTable from "@/components/Animal/HealthCheckLogTable"
export default {
  components: {
    HealthCheckModal,
    HealthCheckLogTable
  },
  props: ["animalData"],
  data() {
    return {
      modal: false,
      healthCheckLog: ""
    }
  },
  mounted() {
    this.loadHealthCheckLog()
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    loadHealthCheckLog() {
      axios
        .get("/wp-json/pasti/v1/healthchecklog/" + this.$route.params.id)
        .then( response => {
          console.log(response.data)
          this.healthCheckLog = response.data
        })
        .catch( error => {
          console.log(error);
        })
    }
  },
  watch: {
    animalData: function() {
      this.loadHealthCheckLog()
    }
  }
}
</script>
