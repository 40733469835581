<template>
  <b-modal
    id="healthcheck-modal"
    title="健康診断記録"
    v-model="active"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="cancel"
  >

    <div class="form-floating mb-3">
      <input
        type="date"
        class="form-control"
        :class="{'is-invalid': !states.healthcheck_date}"
        id="executiondate"
        placeholder="実施日"
        v-model="formData.healthcheck_date"
      >
      <label for="executiondate">実施日</label>
      <div class="invalid-feedback">実施日を入力してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">診断結果</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.diagnosis}"
        v-model="formData.diagnosis"
      >
        <option value="">--</option>
        <option>問題なし</option>
        <option>問題あり</option>
      </select>
      <div class="invalid-feedback">診断結果を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">繁殖可否</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsBreedable}"
        v-model="formData.IsBreedable"
      >
        <option value="">--</option>
        <option value="1">繁殖可</option>
        <option value="0">繁殖不可</option>
      </select>
      <div class="invalid-feedback">繁殖可否を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">問診</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalMedicalExamination}"
        v-model="formData.IsAbnormalMedicalExamination"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">問診異常を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">行動</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalBehavior}"
        v-model="formData.IsAbnormalBehavior"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">異常行動有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">全身</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalWholeBody}"
        v-model="formData.IsAbnormalWholeBody"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">全身の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">眼</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalEye}"
        v-model="formData.IsAbnormalEye"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">眼の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">口</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalMouth}"
        v-model="formData.IsAbnormalMouth"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">口の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">四肢</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalLimb}"
        v-model="formData.IsAbnormalLimb"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">四肢の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">肛門周囲</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalAnus}"
        v-model="formData.IsAbnormalAnus"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">肛門周囲の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">生殖器</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsAbnormalGenital}"
        v-model="formData.IsAbnormalGenital"
      >
        <option value="">--</option>
        <option value="1">異常あり</option>
        <option value="0">異常なし</option>
      </select>
      <div class="invalid-feedback">生殖器の異常の有無を選択してください</div>
    </div>

    <div class="input-group mb-3">
      <span class="input-group-text">生殖器</span>
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsNecessityReport}"
        v-model="formData.IsNecessityReport"
      >
        <option value="">--</option>
        <option value="1">通報必要性あり</option>
        <option value="0">通報必要性なし</option>
      </select>
      <div class="invalid-feedback">通報の必要性の有無を選択してください</div>
    </div>

    <div class="mb-3">
      <label for="healthcheckMemo" class="form-label">備考</label>
      <textarea
        class="form-control"
        id="healthcheckMemo"
        rows="3"
        v-model="formData.other_inspection"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >追加する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["animalData","modal"],
  emits: ["reset", "retriveHealthCheck"],
  data() {
    return {
      active: false,
      formData: {
        healthcheck_date: "",
        diagnosis: "",
        IsBreedable: false,
        IsAbnormalMedicalExamination: false,
        IsAbnormalBehavior: false,
        IsAbnormalWholeBody: false,
        IsAbnormalEye: false,
        IsAbnormalMouth: false,
        IsAbnormalLimb: false,
        IsAbnormalAnus: false,
        IsAbnormalGenital: false,
        IsNecessityReport: false,
        other_inspection: "",
      },
      states: {
        healthcheck_date: true,
        diagnosis: true,
        IsBreedable: true,
        IsAbnormalMedicalExamination: true,
        IsAbnormalBehavior: true,
        IsAbnormalWholeBody: true,
        IsAbnormalEye: true,
        IsAbnormalMouth: true,
        IsAbnormalLimb: true,
        IsAbnormalAnus: true,
        IsAbnormalGenital: true,
        IsNecessityReport: true,
      },
    }
  },
  methods: {
    validate() {
      this.states.healthcheck_date = this.formData.healthcheck_date ? true : false
      this.states.diagnosis = this.formData.diagnosis ? true : false
      this.states.IsBreedable = this.formData.IsBreedable ? true : false
      this.states.IsAbnormalMedicalExamination = this.formData.IsAbnormalMedicalExamination ? true : false
      this.states.IsAbnormalBehavior = this.formData.IsAbnormalBehavior ? true : false
      this.states.IsAbnormalWholeBody = this.formData.IsAbnormalWholeBody ? true : false
      this.states.IsAbnormalEye = this.formData.IsAbnormalEye ? true : false
      this.states.IsAbnormalMouth = this.formData.IsAbnormalMouth ? true : false
      this.states.IsAbnormalLimb = this.formData.IsAbnormalLimb ? true : false
      this.states.IsAbnormalAnus = this.formData.IsAbnormalAnus ? true : false
      this.states.IsAbnormalGenital = this.formData.IsAbnormalGenital ? true : false
      this.states.IsNecessityReport = this.formData.IsNecessityReport ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      console.log(this.formData)
      if(!this.validate())
        return
      
      axios
        .post("/wp-json/pasti/v1/healthcheck", {
          animal_id: this.$route.params.id,
          healthcheck_date: this.formData.healthcheck_date,
          diagnosis: this.formData.diagnosis,
          IsAbnormalMedicalExamination: this.formData.IsAbnormalMedicalExamination,
          IsAbnormalBehavior: this.formData.IsAbnormalBehavior,
          IsAbnormalWholeBody: this.formData.IsAbnormalWholeBody,
          IsAbnormalEye: this.formData.IsAbnormalEye,
          IsAbnormalMouth: this.formData.IsAbnormalMouth,
          IsAbnormalLimb: this.formData.IsAbnormalLimb,
          IsAbnormalAnus: this.formData.IsAbnormalAnus,
          IsAbnormalGenital: this.formData.IsAbnormalGenital,
          IsNecessityReport: this.formData.IsNecessityReport,
          other_inspection: this.formData.other_inspection,
          microchip_no: this.$props.animalData.microchip_no,
          my_number: this.$props.animalData.my_number,
        })
        .then( response => {
          console.log(response)
          this.active = false
          this.resetForm()
          this.$emit('retriveVaccinLog')
        })
        .catch((error) => {
          console.log(error)
        });
    },
    cancel() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      let formdata = this.formData
      Object.keys(formdata).forEach(function(key) {
        formdata[key] = ""
      })
      this.formData = formdata
      let states = this.states
      Object.keys(states).forEach(function(key) {
        states[key] = true
      })
      this.states = states
    }
  },
  watch: {
    modal: function(newVal) {
      this.active = newVal
    }
  }
}
</script>

<style scoped>
.input-group .input-group-text {
  width: 150px;
}
</style>