<template>

    <button
      @click="openModal"
      class="btn btn-primary mb-3"
    >＋予防接種記録</button>

    <VaccinationModal
      :modal="modal"
      :animalData="animalData"
      @reset="closeModal"
      @retriveVaccinLog="loadVaccinLog"
    />

    <VaccinationLogTable
      :vaccinLog="vaccinLog"
    />

</template>

<script>
import axios from "axios"
import VaccinationModal from "@/components/Modal/AddVaccination"
import VaccinationLogTable from "@/components/Animal/VaccinationLogTable"
export default {
  components: {
    VaccinationModal,
    VaccinationLogTable
  },
  props: ["animalData"],
  data() {
    return {
      modal: false,
      vaccinLog: ""
    }
  },
  mounted() {
    this.loadVaccinLog()
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    loadVaccinLog() {
      axios
        .get("/wp-json/pasti/v1/vaccinlog/" + this.$route.params.id)
        .then( response => {
          console.log(response.data)
          this.vaccinLog = response.data
        })
        .catch( error => {
          console.log(error);
        })
    }
  },
  watch: {
    animalData: function() {
      this.loadVaccinLog()
    }
  }
}
</script>
