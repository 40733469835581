<template>
  <b-modal
    id="yobou-modal"
    title="予防処置記録"
    v-model="active"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="cancel"
  >

    <div class="form-floating mb-3">
      <input
        type="date"
        class="form-control"
        :class="{'is-invalid': !states.prevention_date}"
        id="executiondate"
        placeholder="実施日"
        v-model="formData.prevention_date"
      >
      <label for="executiondate">実施日</label>
      <div class="invalid-feedback">実施日を入力してください</div>
    </div>

    <div class="mb-3">
      <label for="yobou-content" class="form-label">予防処置内容</label>
      <textarea
        class="form-control"
        :class="{'is-invalid': !states.prevention_content}"
        id="yobou-content"
        rows="3"
        v-model="formData.prevention_content"
      ></textarea>
      <div class="invalid-feedback">予防処置内容を記入してください</div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >追加する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["animalData","modal"],
  emits: ["reset", "retriveYobou"],
  data() {
    return {
      active: false,
      formData: {
        prevention_date: "",
        prevention_content: "",
      },
      states: {
        prevention_date: true,
        prevention_content: true,

      },
    }
  },
  methods: {
    validate() {
      this.states.prevention_date = this.formData.prevention_date ? true : false
      this.states.prevention_content = this.formData.prevention_content ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      console.log(this.formData)
      if(!this.validate())
        return
      
      axios
        .post("/wp-json/pasti/v1/yoboulog", {
          animal_id: this.$route.params.id,
          prevention_date: this.formData.prevention_date,
          prevention_content: this.formData.prevention_content,
          microchip_no: this.$props.animalData.microchip_no,
          my_number: this.$props.animalData.my_number,
        })
        .then( response => {
          console.log(response)
          //this.active = false
          this.resetForm()
          //this.$emit('retriveVaccinLog')
        })
        .catch((error) => {
          console.log(error)
        });
    },
    cancel() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      let formdata = this.formData
      Object.keys(formdata).forEach(function(key) {
        formdata[key] = ""
      })
      this.formData = formdata
      let states = this.states
      Object.keys(states).forEach(function(key) {
        states[key] = true
      })
      this.states = states
    }
  },
  watch: {
    modal: function(newVal) {
      this.active = newVal
    }
  }
}
</script>
