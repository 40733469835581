<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>日付／担当医</th>
        <th>内容</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in caesareanLog"
        :key="row.rabies_id"
      >
        <td class="index">
          <div class="date">{{row.birth_date}}</div>
          <div class="doctor">{{row.surgeon_name}}</div>
        </td>
        <td class="content">
          {{row.comment}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['caesareanLog'],
}
</script>

<style scoped>
td:not(.content) {
  width: 15%;
}
td.content {}
td.content div {
  font-weight: normal;
  font-size: 0.9rem;
}
</style>