<template>

    <button
      @click="openModal"
      class="btn btn-primary mb-3"
    >＋予防処置記録</button>

    <YobouModal
      :modal="modal"
      :animalData="animalData"
      @reset="closeModal"
      @retriveYobouLog="loadYobouLog"
    />

    <YobouLogTable
      :yobouLog="yobouLog"
    />

</template>

<script>
import axios from "axios"
import YobouModal from "@/components/Modal/AddYobou"
import YobouLogTable from "@/components/Animal/YobouLogTable"
export default {
  components: {
    YobouModal,
    YobouLogTable
  },
  props: ["animalData"],
  data() {
    return {
      modal: false,
      yobouLog: ""
    }
  },
  mounted() {
    this.loadYobouLog()
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    loadYobouLog() {
      axios
        .get("/wp-json/pasti/v1/yoboulog/" + this.$route.params.id)
        .then( response => {
          console.log(response.data)
          this.yobouLog = response.data
        })
        .catch( error => {
          console.log(error);
        })
    }
  },
  watch: {
    animalData: function() {
      this.loadYobouLog()
    }
  }
}
</script>
