<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#kennel"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">飼養動物</h1>
      </div>
    </div>
  </header>

  <main class="p-3">
    <AnimalDetail
      :animalData="animalData"
      :parents="parents"
      @retriveAnimalData="retriveAnimalData"
    />
    <Control
      @selected="setPane"
    />
    <KeepAlive>
      <VaccinationLog
        v-if="pane==='vaccination'"
        :animalData="animalData"
      />
      <HealthcheckLog
        v-else-if="pane==='healthcheck'"
        :animalData="animalData"
      />
      <YobouLog
        v-else-if="pane==='yobou'"
        :animalData="animalData"
      />
      <CaesareanLog
        v-else-if="pane==='caesarean'"
        :animalData="animalData"
      />
      <BreedingLog
        v-else
        :id="animal_id"
        :animalData="animalData"
      />
    </KeepAlive>
  </main>
  
</template>

<script>
import axios from "axios"
import AnimalDetail from "@/components/Animal/Summary4doc"
import Control from "@/components/Animal/Control4doc"
import BreedingLog from "@/components/Animal/LogList"
import VaccinationLog from "@/components/Animal/VaccinationLog"
import HealthcheckLog from "@/components/Animal/HealthcheckLog"
import YobouLog from "@/components/Animal/YobouLog"
import CaesareanLog from "@/components/Animal/CaesareanLog"

export default {
  components: {
    AnimalDetail,
    Control,
    BreedingLog,
    VaccinationLog,
    HealthcheckLog,
    YobouLog,
    CaesareanLog
  },
  data() {
    return {
      pane: "breeding",
      animal_id: "",
      animalData: "",
      parents: "",
    }
  },
  created() {
    
  },
  mounted() {
    this.loadAnimalData(this.$route.params.id)
    this.animal_id = this.$route.params.id

    this.$store.watch(
      (state, getters) => getters['AnimalLog/update'],
      (newValue) => {
        if(newValue === false){
          this.loadAnimalData(this.$route.params.id)
        }
      }
    )
  },
  methods: {
    loadAnimalData(id) {
      axios
        .get("/wp-json/pasti/v1/doc/animal/" + id)
        .then( response => {
          this.animalData = response.data
          this.parents = response.data.parents
        })
        .catch( error => {
          console.log(error);
        })
    },
    setPane(pane) {
      this.pane = pane
    },
    retriveAnimalData(id) {
      this.loadAnimalData(id)
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.params.id)
      if( to.params.id !== from.params.id ){
        this.animal_id = to.params.id
        this.loadAnimalData(to.params.id)
      }
    }
  }
}
</script>