<template>
  <b-modal
    id="caesarean-modal"
    title="帝王切開記録"
    v-model="active"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="cancel"
  >

    <div class="form-floating mb-3">
      <input
        type="date"
        class="form-control"
        :class="{'is-invalid': !states.birth_date}"
        id="executiondate"
        placeholder="帝王切開実施日"
        v-model="formData.birth_date"
      >
      <label for="executiondate">帝王切開実施日</label>
      <div class="invalid-feedback">実施日を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="datetime-local"
        class="form-control"
        :class="{'is-invalid': !states.surgery_end_time}"
        placeholder="手術終了日時"
        v-model="formData.surgery_end_time"
      >
      <label>手術終了日時</label>
      <div class="invalid-feedback">手術終了日時を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <select
        class="form-select"
        v-model="formData.father_id"
      >
        <option value="">--</option>
        <option
          v-for="father in maleList"
          :key="father.id"
          :value="father.id">{{father.name}}</option>
      </select>
      <label>父親</label>
    </div>

    <div class="form-floating mb-3">
      <input
        type="number"
        class="form-control"
        :class="{'is-invalid': !states.number_of_babies}"
        placeholder="出産数"
        v-model="formData.number_of_babies"
        min=0
      >
      <label>出産数</label>
      <div class="invalid-feedback">出産数を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="number"
        class="form-control"
        :class="{'is-invalid': !states.healthy_babies_number}"
        placeholder="健康な新生子数"
        v-model="formData.healthy_babies_number"
        min=0
      >
      <label>健康な新生子数</label>
      <div class="invalid-feedback">健康な新生子数を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="number"
        class="form-control"
        :class="{'is-invalid': !states.disease_babies_number}"
        placeholder="疾病等の新生子数"
        v-model="formData.disease_babies_number"
        min=0
      >
      <label>疾病等の新生子数</label>
      <div class="invalid-feedback">疾病等の新生子数を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <input
        type="number"
        class="form-control"
        :class="{'is-invalid': !states.dead_birth_number}"
        placeholder="死亡した新生子の数"
        v-model="formData.dead_birth_number"
        min=0
      >
      <label>死亡した新生子の数</label>
      <div class="invalid-feedback">死亡した新生子の数を入力してください</div>
    </div>

    <div class="form-floating mb-3">
      <select
        class="form-select"
        :class="{'is-invalid': !states.IsNormalAfterSurgery_Mother}"
        v-model="formData.IsNormalAfterSurgery_Mother"
      >
        <option value="">--</option>
        <option value="1">健康</option>
        <option value="0">不良</option>
      </select>
      <label>術後の母親の状態</label>
      <div class="invalid-feedback">術後の母親の状態を入力してください</div>
    </div>

    <div class="mb-3">
      <label for="caesarean-memo" class="form-label">備考</label>
      <textarea
        class="form-control"
        id="caesarean-memo"
        rows="3"
        v-model="formData.memo"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
      >キャンセル</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="handleOk"
      >追加する</button>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios"
export default {
  props: ["animalData","modal"],
  emits: ["reset", "retriveCaesareanLog"],
  data() {
    return {
      active: false,
      formData: {
        birth_date: "",
        surgery_end_time: "",
        father_id: "",
        number_of_babies: 0,
        healthy_babies_number: 0,
        disease_babies_number: 0,
        dead_birth_number: 0,
        IsNormalAfterSurgery_Mother: "",
        memo: ""
      },
      states: {
        birth_date: true,
        surgery_end_time: true,
        number_of_babies: true,
        healthy_babies_number: true,
        disease_babies_number: true,
        dead_birth_number: true,
        IsNormalAfterSurgery_Mother: true,
      },
    }
  },
  methods: {
    validate() {
      this.states.birth_date = this.formData.birth_date ? true : false
      this.states.surgery_end_time = this.formData.surgery_end_time ? true : false
      this.states.number_of_babies = this.formData.number_of_babies !== "" ? true : false
      this.states.healthy_babies_number = this.formData.healthy_babies_number !== "" ? true : false
      this.states.disease_babies_number = this.formData.disease_babies_number !== "" ? true : false
      this.states.dead_birth_number = this.formData.dead_birth_number !== "" ? true : false
      this.states.IsNormalAfterSurgery_Mother = this.formData.IsNormalAfterSurgery_Mother ? true : false

      let res = true
      let states = {...this.states}
      Object.keys(states).forEach(function(key) {
        res *= states[key]
      })
      return res
    },
    handleOk() {
      if(!this.validate())
        return
      
      axios
        .post("/wp-json/pasti/v1/caesareanlog", {
          animal_id: this.$route.params.id,
          birth_date: this.formData.birth_date,
          surgery_end_time: this.formData.surgery_end_time,
          father_id: this.formData.father_id,
          number_of_babies: this.formData.number_of_babies,
          healthy_babies_number: this.formData.healthy_babies_number,
          disease_babies_number: this.formData.disease_babies_number,
          dead_birth_number: this.formData.dead_birth_number,
          IsNormalAfterSurgery_Mother: this.formData.IsNormalAfterSurgery_Mother,
          microchip_no: this.$props.animalData.microchip_no,
          my_number: this.$props.animalData.my_number,
        })
        .then( response => {
          console.log(response)
          this.active = false
          this.resetForm()
          this.$emit('retriveCaesareanLog')
        })
        .catch((error) => {
          console.log(error)
        });
    },
    cancel() {
      this.resetForm()
      this.$emit('reset')
    },
    resetForm() {
      let formdata = this.formData
      Object.keys(formdata).forEach(function(key) {
        formdata[key] = ""
      })
      this.formData = formdata
      let states = this.states
      Object.keys(states).forEach(function(key) {
        states[key] = true
      })
      this.states = states
    }
  },
  computed: {
    maleList() {
      return this.$store.getters["Doctor/maleList"]
    }
  },
  watch: {
    modal: function(newVal) {
      this.active = newVal
    }
  }
}
</script>
