<template>
  <section id="animalinfo">
    <div class="pic">
      <picture>
        <img :src="animalData.thumbUrl">
      </picture>
      <div>
        <h2>{{animalData.control_number}}</h2>
        <div class="meta">
          <span>{{animalData.species}}</span>
          <span>{{animalData.sex}}</span>
          <span>{{animalData.age}}才</span>
        </div>
      </div>
    </div>
    <div class="data">

      <div class="datasheet parents" v-if="parents">
        <dl>
          <dt>母</dt>
          <dd>
            <div v-if="parents.mother.name" @click="showAnimalDetail(parents.mother.id)">
              <picture>
                <img :src="parents.mother.thumb" :alt="parents.mother.name">
              </picture>
              <div class="clickable">{{parents.mother.name}}</div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>父</dt>
          <dd>
            <div v-if="parents.father.name" @click="showAnimalDetail(parents.father.id)">
              <picture>
                <img :src="parents.father.thumb" :alt="parents.father.name">
              </picture>
              <div class="clickable">{{parents.father.name}}</div>
            </div>
          </dd>
        </dl>
      </div>

      <div class="datasheet summary">
        <dl>
          <dt>市町村登録番号</dt>
          <dd>{{animalData.my_number}}</dd>
        </dl>
        <dl>
          <dt>マイクロチップNo.</dt>
          <dd>{{animalData.microchip_no}}</dd>
        </dl>
        <dl>
          <dt>名前</dt>
          <dd>{{animalData.control_number}}</dd>
        </dl>
        <dl>
          <dt>所属ブリーダー</dt>
          <dd
            class="clickable"
            @click="breederDetail(animalData.breeder_member_id)"
          >
            {{animalData.breeder_member_name}}
          </dd>
        </dl>
        <dl>
          <dt>性別</dt>
          <dd>{{animalData.sex}}</dd>
        </dl>
        <dl>
          <dt>年齢</dt>
          <dd>{{animalData.age}}才</dd>
        </dl>
        <dl>
          <dt>犬種</dt>
          <dd>{{animalData.species}}</dd>
        </dl>
        <dl>
          <dt>毛色</dt>
          <dd>{{animalData.coat_color}}</dd>
        </dl>

        <dl v-if="animalData.sex==='メス'">
          <dt>出産回数</dt>
          <dd>{{animalData.numBirth}}</dd>
        </dl>

        <dl>
          <dt>誕生日</dt>
          <dd>{{animalData.birthday}}</dd>
        </dl>
        <dl>
          <dt>遺伝性疾患</dt>
          <dd>
            <span v-if="animalData.IsExistHereditaryDisease==='1'">遺伝性疾患あり</span>
            <span v-else>NaN</span>
          </dd>
        </dl>
        <dl>
          <dt>販売・譲渡可否</dt>
          <dd>
            <span v-if="animalData.IsOnSale==='1'" class="ok">○</span>
            <span v-else class="ng">×</span>
          </dd>
        </dl>
        <dl>
          <dt>去勢</dt>
          <dd>
            <span v-if="animalData.IsFixed==='1'" class="ok">去勢済</span>
            <span v-else class="ng">未実施</span>
          </dd>
        </dl>
        <dl>
          <dt>現況</dt>
          <dd>
            <span v-if="animalData.IsRetired==='1'" class="ok">引退済</span>
            <span v-else class="ng">現役</span>
          </dd>
        </dl>

      </div>


    </div>

  </section>
</template>

<script>
export default {
  props: ["animalData", "parents"],
  emits: ["retriveAnimalData"],
  methods: {
    breederDetail(id) {
      this.$router.push('/breeder/' + id)
    },
    showAnimalDetail(id) {
      this.$emit("retriveAnimalData", id)
      this.$router.push('/animal/' + id)
    }
  },
}
</script>

<style scoped>
#animalinfo {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}
#animalinfo .pic {
  width: 25%;
  flex-basis: 25%;
  margin-right: 30px;
}
#animalinfo .data {
  flex: 1;
}
#animalinfo .pic picture {
  display: block;
  min-height: 200px;
  border-radius: 15px;
  overflow: hidden;
}
#animalinfo .pic > div {
  padding: 15px 0;
}
#animalinfo .pic img {
  width: 100%;
  height: auto;
}
#animalinfo .pic h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 4px;
}
#animalinfo .pic .meta span {
  display: inline-block;
  margin-right: 0.5em;
}
#animalinfo .summary {
  margin-bottom: 25px;
}

.parents dd > div {
  display: flex;
  align-items: center;
}
.parents dd picture {
  width: 30px;
  height: 30px;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}
.parents dd picture img {
  width: 100%;
  height: auto;
}

</style>