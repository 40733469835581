<template>

    <button
      @click="openModal"
      class="btn btn-primary mb-3"
    >＋帝王切開記録</button>

    <CaesareanModal
      :modal="modal"
      :animalData="animalData"
      @reset="closeModal"
      @retriveCaesareanLog="loadCaesareanLog"
    />

    <CaesareanLogTable
      :caesareanLog="caesareanLog"
    />

</template>

<script>
import axios from "axios"
import CaesareanModal from "@/components/Modal/Caesarean"
import CaesareanLogTable from "@/components/Animal/CaesareanLogTable"
export default {
  components: {
    CaesareanModal,
    CaesareanLogTable
  },
  props: ["animalData"],
  data() {
    return {
      modal: false,
      caesareanLog: ""
    }
  },
  mounted() {
    this.loadCaesareanLog()
  },
  methods: {
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
    },
    loadCaesareanLog() {
      axios
        .get("/wp-json/pasti/v1/caesareanlog/" + this.$route.params.id)
        .then( response => {
          console.log(response.data)
          this.caesareanLog = response.data
        })
        .catch( error => {
          console.log(error);
        })
    }
  },
  watch: {
    animalData: function(newVal) {
      this.$store.dispatch("Doctor/loadAnimalList", newVal.breeder_member_id)
      this.loadCaesareanLog()
    }
  }
}
</script>
